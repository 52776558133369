(function () {
    angular.module('informaApp')
        .service('BoxPlotChartCreator', ['d3', 'BasicChartTemplate', 'ChartElementsHelper', 'BoxPlotItem', 'Tooltip', BoxPlotChartCreatorService]);

    function BoxPlotChartCreatorService(d3, BasicChartTemplate, ChartElementsHelper, BoxPlotItem, Tooltip) {
        var defaultConfig = {
            margin: {
                left: 0,
                top: 10,
                bottom: 200
            },

            xAxis: {
                labels: ['X', 'Axis']
            },

            height: 600,

            grid: true,

            colors: ChartElementsHelper.colors
        };

        return function (chartContainer, data, config) {
            config = _.merge({}, defaultConfig, config);

            var basicChart = new BasicChartTemplate(chartContainer, config);

            basicChart.updateYAxis(data.yRange.min, data.yRange.max, config.animationDurationMs || 0);

            var mappedData = mapData(data);

            basicChart.updateXAxis(getGroupIndexes(mappedData), getXTickFormatter(mappedData.numbers), mappedData.names);

            var tooltip = new Tooltip(basicChart.svg, {y: -10}, 'box-plot-tooltip');

            var boxes = createBoxes(basicChart, mappedData, config, tooltip);

            this.destroy = function () {
                basicChart.destroy();

                d3.selectAll('.d3-tip.box-plot-tooltip').remove();
            };

            this.showLabels = function () {
                createBoxLabels(basicChart, boxes);
            };

            this.hideLabels = function () {
                basicChart.chartContainer
                    .selectAll('text.box-plot-label, line.anchor')
                    .remove();
            }

            this.setXAxisVisibility = (visibility) => {
                basicChart.setXAxisVisibility(visibility);
            }
        };

        function createBoxes(basicChart, mappedData, config, tooltip) {
            var width = 100;

            var boxes = [];

            if (!mappedData.source[i] instanceof Array) {
                mappedData.source = [mappedData.source];
            }

            for (var i = 0; i < mappedData.source.length; i++) {
                const group = createItemGroup(basicChart, mappedData, i, config, tooltip)

                boxes.push.apply(boxes, group);
            }

            return boxes;
        }

        function mapData(data) {
            return {
                names: data.items.map(function (x) {
                    return x.name;
                }),
                numbers: data.items.map(function (x) {
                    return x.number;
                }),
                source: data.items.map(function (x) {
                    return x.source;
                })
            }
        }

        function getXTickFormatter(numbers) {
            return function (x) {
                return numbers[x];
            }
        }

        function getGroupIndexes(data) {
            return data.numbers.map(function (x, i) {
                return i
            });
        }

        function createItemGroup(basicChart, data, index, config, tooltip) {
            var boxMargin = 10;
            var width = 100 - (data.source.length - 1) * 20;

            var startX = -(width + boxMargin) * (data.source[index].filter(x => !!x).length / 2 - 0.5);

            let offsetIndex = 0;
            var boxes = data.source[index].map(function (x, i) {
                if (!x) {
                    return null;
                }

                return createBox(basicChart, x, index, startX + (boxMargin + width) * offsetIndex++, width, config.colors[i], tooltip);
            });

            return boxes.filter(filterNulls);
        }

        function filterNulls(x) {
            return x !== null;
        }

        function createBox(basicChart, item, xValue, xOffset, width, color, tooltip) {
            if (!item) {
                return;
            }

            var box = new BoxPlotItem(basicChart.chartContainer, {
                x: basicChart.getX(xValue) + xOffset,
                median: basicChart.getY(item.median),
                q1: basicChart.getY(item.q1),
                q3: basicChart.getY(item.q3),
                upperBorder: basicChart.getY(item.upperBorder),
                lowerBorder: basicChart.getY(item.lowerBorder),
                outliers: item.outliers.map(function (v) {
                    return basicChart.getY(v);
                }),
                color: color,
                width: width
            });

            box.container.on('mouseover', function () {
                tooltip.show([
                    {text: 'Max: ' + item.max + ' yr'},
                    {text: 'Q3: ' + item.q3 + ' yr'},
                    {text: 'Median: ' + item.median + ' yr', bold: true},
                    {text: 'Q1: ' + item.q1 + ' yr'},
                    {text: 'Min: ' + item.min + ' yr'},
                ]);
            });

            box.container.on('mouseout', function () {
                tooltip.hide();
            });

            return {
                box: box,
                x: basicChart.getX(xValue) + xOffset,
                width: width,
                data: item
            };
        }

        function createBoxLabels(basicChart, boxes) {
            var labelRects = getLabelRects(basicChart, boxes);

            var labels = createLabels(basicChart.chartContainer, labelRects)
                .each(function (d, i) {
                    var size = this.getBBox();

                    labelRects[i].width = size.width;
                    labelRects[i].height = size.height;
                });

            var anchors = createAnchors(basicChart.chartContainer, labelRects);

            var size = basicChart.svg.node().getBoundingClientRect();

            ChartElementsHelper.removeOverlapping(labels, anchors, labelRects, size.width, size.height);
        }



        function getLabelRects(basicChart, boxes) {
            var labelRects = boxes.map(function (box) {
                var labelMappings = getMappingForLabels(box.data);

                return labelMappings.map(function (mapping) {
                    return {
                        x: getLabelXOffset(box, mapping.value),
                        y: basicChart.getY(mapping.value),
                        width: 0,
                        height: 0,
                        name: mapping.prefix + ': ' + mapping.value
                    };
                });
            });

            return [].concat.apply([], labelRects);
        }

        function getMappingForLabels(data) {
            return [
                {prefix: 'Median', value: data.median},
                {prefix: 'Q1', value: data.q1},
                {prefix: 'Q3', value: data.q3},
                {prefix: 'Upper', value: data.upperBorder},
                {prefix: 'Lower', value: data.lowerBorder},
            ]
        }

        function getLabelXOffset(box, value) {
            if (value === box.data.upperBorder || value === box.data.lowerBorder) {
                return box.x + box.width / 4;
            }

            return isOutlier(box.data.outliers, value)
                ? box.x
                : box.x + box.width / 2;
        }

        function isOutlier(outliers, value) {
            return outliers && outliers.indexOf(value) > -1;
        }

        function createAnchors(container, labelRects) {
            return container
                .selectAll('line.anchor')
                .data(labelRects)
                .enter()
                .append('line')
                .attr('class', 'anchor')
                .attr('x1', function (d) {
                    return (d.x);
                })
                .attr('y1', function (d) {
                    return (d.y);
                })
                .attr('x2', function (d) {
                    return (d.x);
                })
                .attr('y2', function (d) {
                    return (d.y);
                })
                .attr('stroke-width', 0.6)
                .attr('stroke', 'gray');
        }

        function createLabels(container, labelRects) {
            var labels = container
                .selectAll('text.box-plot-label')
                .data(labelRects)
                .enter()
                .append('text')
                .attr('class', 'box-plot-label')
                .attr('font-size', '10px')
                .text(function (d) {
                    return d.name;
                })
                .attr('x', function (d) {
                    return d.x;
                } )
                .attr('y', function (d) {
                    return d.y;
                });

            labels.each(function (x) {
                ChartElementsHelper.addBorderForText(d3.select(this));
            });

            return labels;
        }
    }
})();